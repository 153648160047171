import { init as initApm } from '@elastic/apm-rum';

const apm = initApm({
  serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
  serverUrl: process.env.REACT_APP_APM_ENDPOINT,
  serviceVersion: '',
  environment: process.env.REACT_APP_ENV,
  active: process.env.REACT_APP_ENV === 'production',
});

export default apm;

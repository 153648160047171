import nodeRsa from 'node-rsa';

export default data => {
    const nodeRsaPublicKey = nodeRsa(
        process.env.REACT_APP_RSA_KEY,
        'pkcs8-public',
        { environment: 'browser' }
    );
    return nodeRsaPublicKey.encrypt(data, 'base64');
};

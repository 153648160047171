import React, { useState, useEffect, useRef } from 'react';
import {
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    FormText,
} from 'reactstrap';
import CurrencyFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlacesAutocomplete, {
    geocodeByPlaceId,
} from 'react-places-autocomplete';
import { Spinner } from './functionalComponents/Spinners';
import { decrypt } from '../utils/rsaEncryptDecrpt';
import '../scss/components/_inputs.scss';

export const ErrorMessage = ({
    message,
    className = '',
    labelIcon,
    iconClassName = '',
}) =>
    !message ? null : (
        <p className={`mt-2 msg-error ${className}`}>
            {labelIcon ? (
                <FontAwesomeIcon icon={labelIcon} className={iconClassName} />
            ) : null}
            {message}
        </p>
    );

ErrorMessage.defaultProps = {
    labelIcon: '',
    iconClassName: '',
};

export const CurrencyInput = ({
    children,
    parentClassName,
    titleClassName,
    title,
    value,
    onChange,
    errorMessage,
    errorMessageClass,
    transparent = false,
    ...props
}) => (
    <div
        className={`${transparent ? 'filter-item' : 'flex-column input-item'
            } ${parentClassName}`}
    >
        <Label className={`filter-title ${titleClassName}`} for="transactionId">
            {title}
        </Label>
        <CurrencyFormat
            placeholder="..."
            value={value}
            onChange={onChange}
            {...props}
        />
        {errorMessage ? (
            <ErrorMessage
                message={errorMessage}
                className={errorMessageClass}
            />
        ) : (
            children
        )}
    </div>
);

export const CustomInput = ({
    children,
    parentClassName,
    title,
    value = '',
    onChange,
    errorMessage,
    errorMessageClass,
    transparent = false,
    placeholder = '...',
    labelClassName = '',
    id,
    maxLength,
    onBlur,
    ...props
}) => (
    <div
        className={`${transparent ? 'filter-item' : 'flex-column input-item'
            } ${parentClassName}`}
    >
        <Label className={`filter-title ${labelClassName}`} for={id}>
            {title}
        </Label>
        <Input
            onBlur={onBlur}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            id={id}
            {...props}
        />
        {errorMessage ? (
            <ErrorMessage
                message={errorMessage}
                className={errorMessageClass}
            />
        ) : null}
        {children}
    </div>
);

export const CustomInputIcon = ({
    children,
    parentClassName,
    title,
    value,
    onChange,
    errorMessage,
    errorMessageClass,
    transparent = false,
    icon,
    helperText = '',
    ...props
}) => (
    <div
        className={`${transparent ? 'filter-item' : 'flex-column input-item'
            } ${parentClassName}`}
    >
        <Label className="filter-title" for="transactionId">
            {title}
        </Label>
        <InputGroup>
            <Input
                placeholder="..."
                value={value}
                onChange={onChange}
                {...props}
            />
            <InputGroupAddon addonType="append">
                <InputGroupText>{icon}</InputGroupText>
            </InputGroupAddon>
        </InputGroup>
        {helperText ? <FormText color="muted">{helperText}</FormText> : <></>}
        {errorMessage ? (
            <ErrorMessage
                message={errorMessage}
                className={errorMessageClass}
            />
        ) : (
            children
        )}
    </div>
);

export const Textarea = ({
    title,
    onChange,
    value,
    parentClassName,
    inputClassName,
    ...props
}) => (
    <div className={`flex-column input-item ${parentClassName}`}>
        <Label className="filter-title">{title}</Label>
        <textarea
            placeholder="..."
            className={inputClassName}
            type="text"
            value={value}
            onChange={onChange}
            {...props}
        />
    </div>
);

export const CustomCheckBox = ({
    title,
    onChange,
    checked,
    parentClassName,
    // inputClassName,
    ...props
}) => (
    <div className={`flex-column input-item ${parentClassName}`}>
        <Label
            for="privacyNotice"
            className="vertical-center labelCustomCheckBox"
        >
            <input
                // className={inputClassName}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                {...props}
            />
            {title}
        </Label>
    </div>
);

export const LocationSearchInput = ({
    parentClassName,
    transparent = false,
    placeholder = '...',
    countryCode = '',
    handleLocationSearch = () => { },
    onChange,
    value = '',
    isClearable = false,
    showLocationSearchError = false,
    errorMessage = '',
    labelClassName,
    title,
    maxLength,
    ...props
}) => {
    const scriptRef = useRef(null);
    const [locationSearchError, handleLocationSearchError] = useState('');
    const [mapIsReady, handleMapReady] = useState(false);
    const addressKeyMapping = {
        administrative_area_level_3: 'city',
        address: 'address',
        locality: 'city',
        administrative_area_level_1: 'state',
        country: 'country',
        postal_code: 'postalCode',
    };

    const handleError = message => {
        if (showLocationSearchError) {
            handleLocationSearchError(message);
        }
    };

    useEffect(() => {
        const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
        const DECRYPTED_API_KEY = decrypt(API_KEY);
        scriptRef.current = document.createElement('script');
        scriptRef.current.src = `https://maps.googleapis.com/maps/api/js?key=${DECRYPTED_API_KEY}&libraries=places&language=en`;
        scriptRef.current.async = true;
        scriptRef.current.defer = true;
        scriptRef.current.addEventListener('load', () => {
            handleMapReady(true);
        });

        document.body.appendChild(scriptRef.current);

        return () => {
            document.body.removeChild(scriptRef.current);
            window.google = {};
        };
    }, []);

    const handleAddressChange = address => {
        handleError('');
        onChange(address);
    };

    const shortenTextLength = (textField, length) => {
        if (textField?.length > length) {
            const textFieldComponents = textField?.split(', ');
            textFieldComponents.pop();
            const updatedTextField = textFieldComponents?.join(', ');
            if (updatedTextField?.length <= length) return updatedTextField;
            return shortenTextLength(updatedTextField, length);
        }
        return textField;
    };

    const handleAddressSelection = (addressText, placeId) => {
        handleError('');
        return geocodeByPlaceId(placeId)
            .then(([result]) => {
                handleError('');
                const { address_components, formatted_address } = result;
                const addressComponents = [
                    ...[
                        {
                            long_name: formatted_address,
                            short_name: formatted_address,
                            types: ['address'],
                        },
                    ],
                    ...address_components,
                ];

                const addressDetails = addressComponents?.reduce(
                    (finalAddress, address) => {
                        const [addressKey] = address?.types;
                        const addressValue = address?.long_name;
                        let addressDetail = {};
                        if (
                            Object.keys(addressKeyMapping).includes(addressKey)
                        ) {
                            if (addressKey !== 'address') {
                                const updatedAddressText = finalAddress?.address
                                    ?.split(', ')
                                    .filter(
                                        addressComponent =>
                                            !new RegExp(
                                                addressValue,
                                                'gi'
                                            ).test(addressComponent)
                                    )
                                    ?.join(', ');
                                addressDetail = {
                                    ...{
                                        [addressKeyMapping[addressKey] ?? [
                                            addressKey,
                                        ]]: addressValue,
                                    },
                                    ...(updatedAddressText
                                        ? { address: updatedAddressText }
                                        : {}),
                                };
                            } else {
                                addressDetail = {
                                    address: formatted_address,
                                };
                            }
                        }
                        return { ...finalAddress, ...addressDetail };
                    },
                    {}
                );
                if (maxLength) {
                    addressDetails.address = shortenTextLength(
                        addressDetails?.address,
                        maxLength
                    );
                }
                onChange(addressDetails?.address);
                handleLocationSearch(addressDetails);
                return addressDetails;
            })
            .catch(error => handleError(error));
    };

    if (!mapIsReady) return <Spinner type="regular" />;
    return (
        <div
            className={`${transparent ? 'filter-item' : 'flex-column input-item'
                } ${parentClassName} position-relative`}
        >
            <Label
                className={`filter-title ${labelClassName}`}
                for="transactionId"
            >
                {title}
            </Label>
            <PlacesAutocomplete
                value={value}
                onChange={handleAddressChange}
                onSelect={handleAddressSelection}
                onError={handleError}
                searchOptions={{
                    componentRestrictions: { country: countryCode },
                }}
                shouldFetchSuggestions={value?.length > 2}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <>
                        <Input
                            {...getInputProps({
                                placeholder: placeholder,
                            })}
                            {...props}
                        />
                        {suggestions.length > 0 && (
                            <div className="select-places-menu items">
                                {loading && <Spinner type="circular-inline" />}
                                {isClearable && value?.length > 0 && (
                                    <div
                                        className="clear-button"
                                        onClick={() => handleLocationSearch({})}
                                    >
                                        <i className="fa fa-times"></i>
                                    </div>
                                )}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'is-focused'
                                        : '';
                                    return (
                                        <div
                                            {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                    className: `select-places-option ${className}`,
                                                }
                                            )}
                                        >
                                            <span>
                                                {suggestion.description}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </PlacesAutocomplete>
            {errorMessage || locationSearchError ? (
                <ErrorMessage message={errorMessage || locationSearchError} />
            ) : null}
        </div>
    );
};

import nodeRsa from 'node-rsa';

export const encrypt = data => {
    const nodeRsaPublicKey = nodeRsa(
        process.env.REACT_APP_LOCALSTORAGE_RSA_PUBLIC_KEY
    );
    return nodeRsaPublicKey.encrypt(data, 'base64');
};

export const decrypt = data => {
    const nodeRsaPrivateKey = nodeRsa(
        process.env.REACT_APP_LOCALSTORAGE_RSA_PRIVATE_KEY
    );
    return nodeRsaPrivateKey.decrypt(data, 'utf8');
};
